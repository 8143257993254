import React from "react";
import { Input, PhoneInput, Checkbox} from "../ui/input";
import { Select } from "../ui/select";
import FormLayout from "./form-layout";
import { AFFILIATEROLE } from "../../constants";

const ReferralForm = ({ methods, handleSubmit }) => {
  return (
    <FormLayout methods={methods} onSubmit={handleSubmit}>
      <Input
        id="id-name"
        name="name"
        label="Name"
        type="text"
        validation={{
          required: `Required`,
          pattern: {
            value: /^[\p{L}\s']+$/u,
            message: `Names can only contain alphabets, letters, spaces, and apostrophes.`,
          },
          minLength: {
            value: 3,
            message: `Name must be at least 3 characters long.`,
          },
        }}
      />
      <Input
        id="id-email"
        name="email"
        label="Email"
        type="email"
        validation={{
          required: "Required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Please enter a valid email address.",
          },
        }}
      />
      <PhoneInput
        id="id-phone"
        name="phone"
        label="Phone"
        type="text"
        placeholder="(999)-999-9999"
        validation={{
          required: "Required",
        }}
      />
      <Input
        id="id-referral"
        name="referral_code"
        label="I want my Referral code to be"
        type="text"
        instructions="Note: 1 word, no spaces and not a phrase"
        placeholder="Type your referral code..."
        validation={{
          required: "Required",
          pattern: {
            value: /^\S+$/,
            message: "Referral code must be a single word with no spaces.",
          },
        }}
      />
      <Select
        name="affiliate_type"
        label="I am a..."
        placeholder="Please Select"
        options={AFFILIATEROLE}
        validation={{ required: "Required" }}
      />
      <Checkbox
        name="subscribe"
        label="By clicking here you opt-in to occasional SMS messages for communication purposes"
        id="newsletter-checkbox"
        defaultChecked={false}
      />
    </FormLayout>
  );
};

export default ReferralForm;
