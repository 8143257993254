import React from "react";
import { Link } from "react-router-dom";
import heroImage from "../../images/about-hero.jpg";

const Hero = () => {
  return (
    <section className="relative">
      <div
        className="absolute inset-0 bg-slate-900 pointer-events-none -z-10"
        aria-hidden="true"
      >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-10"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6">
        <div className="pt-24 pb-12 md:pt-40 md:pb-32 flex justify-center">
          <div className="text-center max-w-3xl" data-aos="fade-right">
            <Link
              to="/detailed-form"
              className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition-colors duration-300"
            >
             <h1 className="h2 font-playfair-display text-slate-100 mb-4">
              Apply Now
            </h1>
            </Link>
            <p className="text-xl text-white mt-6 uppercase">
            Get Funding Now
            </p>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
