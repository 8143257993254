import React from "react";
import ReactDOM from "react-dom";

const ConfirmDeleteModal = ({ isOpen, closeModal, itemId, uri }) => {
  const overlayStyle = isOpen ? "block" : "hidden";
  console.log(uri)
  const onDelete = async () => {
    const response = await fetch(uri, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({id: itemId}),
    });
    if (!response.ok) {
        throw new Error("Failed to fetch records");
    }
    closeModal();
    window.location.reload();
  }

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={`fixed inset-0 z-50 overflow-auto ${overlayStyle}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        <div className="modal-container bg-white rounded-lg shadow-lg z-50 w-full sm:max-w-lg lg:max-w-2xl">
          <div className="modal-content p-6">
            <div className="modal-header flex justify-between items-center border-b pb-4">
              <h3 className="modal-title text-lg font-semibold text-gray-800">Confirm Deletion</h3>
              {/* Close button */}
              <button
                className="modal-close text-red-500 text-2xl focus:outline-none"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
            <div className="modal-body text-gray-700 max-h-96 overflow-y-auto">
              {/* Confirmation Message */}
              <div className="text-center">
                <p className="text-lg">
                  Are you sure you want to delete{" "}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer bg-gray-100 py-3 px-6 space-x-2 flex justify-end">
            {/* Cancel button */}
            <button
              className="text-gray-500 font-semibold hover:text-gray-700"
              onClick={closeModal}
            >
              Cancel
            </button>
            {/* Delete button */}
            <button
              className="text-red-500 font-semibold hover:text-red-700"
              onClick={onDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmDeleteModal;
