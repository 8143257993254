import React from "react";
import { MdWarning } from "react-icons/md";

const Error = () => {
  return (
    <tr>
      <td colSpan="100%" className="text-center py-4">
        <div className="flex items-center justify-center text-red-600">
          <MdWarning size={24} className="mr-1" />
          Some unknown error has occurred
        </div>
      </td>
    </tr>
  );
};

export default Error;
