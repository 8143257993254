import React from "react";
import { useFormContext } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../utils";

const SelectOption = ({ value, label, disabled }) => {
  return (
    <option value={value} disabled={disabled}>
      {label}
    </option>
  );
};

export const Select = ({ name, label, options, placeholder, validation }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  return (
    <div className="w-full">
      <label className="block text-gray-700 text-sm font-bold">
        {label}
      </label>
      <select
        className={`block w-full px-3 py-2 text-sm border rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
          isInvalid ? "border-red-600" : "border-gray-300"
        }`}
        {...register(name, validation)}
        defaultValue=""
      >
        <SelectOption value="" label={placeholder} disabled={true} />
        {options.map((option) => (
          <SelectOption
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </select>
      {inputErrors && (
        <p className="mt-2 text-sm text-red-600">{inputErrors.message}</p>
      )}
    </div>
  );
};

export default Select;
