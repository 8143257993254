import React from "react";
import { FormProvider } from "react-hook-form";
import { isFormInvalid } from "../../utils";

const FormLayout = ({ methods, onSubmit, children }) => {
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex justify-center flex-col w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mt-8 space-y-4"
      >
        {children}
        <button
          className="bg-blue-900 hover:bg-blue-950 disabled:bg-gray-300 text-white font-bold py-2 px-4 max-w-md mx-auto rounded focus:outline-none focus:shadow-outline"
          type="submit"
          disabled={
            isFormInvalid(methods.formState.errors) ||
            methods.formState.isSubmitting
          }
        >
          {methods.formState.isSubmitting ? "...Submitting" : "Submit"}
        </button>
      </form>
    </FormProvider>
  );
};

export default FormLayout;
