import React, {Suspense} from "react";
import { useEffect } from "react";
import AOS from "aos";
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import "./App.css";
import "aos/dist/aos.css";
import PageNotFound from "./pages/404/404";
import Layout from "./layout";
import AdminLayout from "./admin-layout";
import AttorneyLandingPage from "./pages/attorney/attorney";
import Admin from "./pages/admin/admin";
import Referral from "./pages/referral/referral";
import { useAuth0 } from "@auth0/auth0-react";
import TransactionalFunding from "./pages/transactional-funding/transactional-funding";
import Unauthorized from "./pages/401/401"
import DetailedFormPage from "./pages/detailed-form/form";
import About from "./pages/about/about";
import FAQS from "./pages/faqs/faqs";

export const ProtectedRoute = () => {
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      if (isLoading) return; 

      if (!isAuthenticated) {
        await loginWithRedirect();
      } else {
        await getAccessTokenSilently();
        const userRoles = user['https://my-roles.com/roles'] || [];

        if (userRoles.includes("Admin")) {
          navigate("/admin/dashboard");
        } else {
          navigate("/unauthorized");
        }
      }
    };

    checkAuth();
  }, [isAuthenticated, isLoading, loginWithRedirect, navigate, getAccessTokenSilently, user]);

  return isAuthenticated && user['https://my-roles.com/roles']?.includes("Admin") ? <Outlet /> : null;
};
function App() {
  React.useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden min-w-full box-border">
      <Routes>
        <Route path="/admin" element={<ProtectedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="dashboard" element={<Admin />} />
          </Route>
        </Route>

        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Home />} />
          <Route path="detailed-form" element={<DetailedFormPage />} />
          <Route 
            path="about" 
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <About />
              </Suspense>
            } 
          />
          <Route path="contact" element={<Contact />} />
          <Route path="attorney" element={<AttorneyLandingPage />} />
          <Route
            path="faqs"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <FAQS/>
              </Suspense>
            }
          
          />
          <Route path="referral" element={<Referral />} />
          <Route
            path="transactional-funding"
            element={<TransactionalFunding />}
          />
           <Route path="unauthorized" element={<Unauthorized/>} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
