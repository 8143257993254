import React from "react";
import Accordion from "../../components/utils/accordion";
import Hero from "../../components/ui/hero";
import { Link } from "react-router-dom";


const FAQS = () => {
  return (
    <>
      <Hero title="FREQUENTLY ASKED QUESTIONS"/>
      <section className="bg-slate-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20 border-t border-slate-50">
            {/* Section header */}

            {/* Faqs */}
            <ul className="max-w-3xl mx-auto divide-y divide-slate-200">
              <Accordion title="What is transactional funding?">
              Transactional funding is a short-term loan specifically designed to facilitate
              back-to-back real estate transactions. It&#39;s a financial strategy commonly used
              by real estate investors to temporarily finance the purchase of a property that
              they intend to sell quickly. This type of funding is typically used in scenarios
              where an investor buys a property and sells it on the same day.
              </Accordion>
              <Accordion title="Why use transactional funding?">
                Provides immediate capital to close deals quickly, without long-term commitment or financial risk. 
                Offers short-term funding to overcome liquidity constraints, ideal for quick property acquisitions and double closings.
              </Accordion>
              <Accordion title="How to apply for transactional funding?">
                All you have to do is to submit a <Link to="/detailed-form" className="text-blue-600">simple form</Link> and our team will reach out to you.
              </Accordion>
              {/* <Accordion title="How to contact our attorneys?">
                Our attorneys can help you in closing deals. Feel free to <Link to="/attorney" className="text-blue-600">submit your interest</Link> and our attorneys will be in touch with you soon.
              </Accordion> */}
              <span
                className="block border-t border-gray-200"
                aria-hidden="true"
              ></span>
            </ul>
            <div className="max-w-fit mx-auto my-8">
            <Link
                  to="/contact"
                  className="btn  text-white font-monserrat bg-blue-600 hover:bg-blue-700"
                >
                  Have another question?
             </Link>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQS;
