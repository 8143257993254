import React, { useState } from "react";
import Modal from "./modal";
import { transformData } from "./transformData";
import { MdCached } from "react-icons/md";

const Loading = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-gray-900 bg-opacity-50">
      <div className="flex items-center justify-center text-gray-600">
        <MdCached size={24} className="mr-1 animate-spin" />
        Loading...
      </div>
    </div>
  );
};

const Table = ({ data, deleteUri, isClickable = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [header, setIsHeader] = useState(null);
  const [additionalData, setIsAdditionalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = async (data) => {
    const {id, submissionDate, ...rest} = data;
    setIsLoading(true);
    const response = await fetch('/form/details', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({id}),
    });
    if (!response.ok) {
          throw new Error("Failed to fetch records");
    }
    
    const responseData = await response.json();
    if(responseData) {
    const {leadId, ...misc} = responseData;
      const transformedData = transformData(misc);
      setIsAdditionalData(transformedData);  
    }
 
    setIsLoading(false);
    setIsHeader(rest);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isLoading && <Loading/>}
      {data.map((l) => {
        return (
          <tr key={l.id} 
          className={`cursor-pointer bg-gray-300 ${isClickable && "hover:bg-white transition-colors"}`}
          onClick={() => isClickable && openModal(l)}>
            {Object.keys(l)
              .filter((key) => key !== "id" && key !== "submissionDate")
              .map((key) => (
                <td
                  key={key}
                  className="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style={{ minWidth: '150px' }} 
                >
                  <div className="text-sm leading-5 text-gray-900">
                    {l[key]}
                  </div>
                </td>
              ))}
          </tr>
        );
      })}
      {isClickable && <Modal isOpen={isModalOpen} closeModal={closeModal} header={header} additionalData={additionalData} id={data[0].id} deleteUri={deleteUri}/>}
    </>
  );
};

export default Table;
