import React from "react";
import { useSidebar } from "./useSidebar";
import Logo from "../../images/Brand Logo white.png";
import { Link, useLocation } from "react-router-dom";
import { DASHBOARDLINKS } from "../../constants";

const Sidebar = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const location = useLocation();
  const activeClass = "border-blue-500 text-blue-500"; // Example classes for active state
  const inactiveClass = "border-transparent text-gray-500"; // Example classes for inactive state

  return (
    <div className="flex">
      {/* Backdrop */}
      <div
        className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
          isOpen ? "block" : "hidden"
        }`}
        onClick={() => setIsOpen(false)}
      />
      {/* End Backdrop */}

      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-slate-900 lg:translate-x-0 lg:static lg:inset-0 ${
          isOpen ? "translate-x-0 ease-out" : "-translate-x-full ease-in"
        }`}
      >
        <div className="flex items-center justify-center mt-8">
          <div className="flex items-center">
            <div className="shrink-0 mr-4">
              <Link to="/">
                <img src={Logo} width="200" height="200" alt="logo" />
              </Link>
            </div>
          </div>
        </div>

        <nav className="mt-10">
          {DASHBOARDLINKS.map((li) => {
            return (
              <Link
                id={li.url}
                key={li.url}
                className={`flex items-center text-white px-6 py-2 mt-4 duration-200 border-l-4 ${
                  location.pathname === li.url ? activeClass : inactiveClass
                }`}
                to={li.url}
              >
                <span className="mx-4">{li.name}</span>
              </Link>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
