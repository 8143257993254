import React, { useState } from "react";
import Hero from "./hero";
import FeaturesBlock from "./features-block";
import AttorneyForm from "../../components/form/attorney-form";
import useAttorneyFormSubmit from "../../hooks/useAttorneyFormSubmit";
import SuccessMessage from "../../components/success-message";

const AttorneyLandingPage = () => {
  const [isFormAlreadySubmitted, setIsFormAlreadySubmitted] = useState(
    localStorage.getItem("attorneyform") === "true"
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { methods, handleSubmit } = useAttorneyFormSubmit({
    setOnSuccess: setShowSuccessMessage,
  });
  const handleResetForm = () => {
    localStorage.removeItem("attorneyform");
    setIsFormAlreadySubmitted(false);
    setShowSuccessMessage(false);
  };
  return (
    <>
      <Hero title={"WORKING WITH US"} />
      <FeaturesBlock />
      <div className="w-full max-w-3xl mx-auto px-4 sm:px-6">
        {showSuccessMessage || isFormAlreadySubmitted ? (
          <>
            <SuccessMessage message={"We'll be in touch soon :)"} className="text-black"/>
            <span className="flex justify-center items-center gap-2 text-black-100">
              Want to submit the form again?
              <span
                className="underline hover:cursor-pointer"
                onClick={handleResetForm}
              >
                Click Here
              </span>
            </span>
          </>
        ) : (
          <AttorneyForm methods={methods} handleSubmit={handleSubmit} />
        )}
      </div>
    </>
  );
};

export default AttorneyLandingPage;
