import { useState } from "react";
import { Tab } from "@headlessui/react";

const steps = [
  {
    title: "Finding a Property",
    content:
      "The investor identifies a property they wish to purchase and subsequently sell. This property could be undervalued, distressed, or otherwise appealing to potential buyers.",
  },
  {
    title: "Identifying a Buyer",
    content:
      "Before obtaining transactional funding, the investor must have an end buyer lined up. This ensures that the property can be sold immediately after purchase, mitigating the risk for the lender.",
  },
  {
    title: "Securing Transactional Funding",
    content:
      "The investor approaches a transactional lender to secure the funds needed for the initial purchase. This lender agrees to provide the necessary capital for the closings",
  },
  {
    title: "Executing the Purchase (A to B)",
    content:
      "Using the funds from the transactional lender, the investor purchases the property from the original seller. This transaction is known as the A to B transaction.",
  },
  {
    title: "Selling the Property (B to C)",
    content:
      "Immediately following the initial purchase, the investor sells the property to the end buyer. This is known as the B to C transaction. The sale proceeds from this transaction are then used to repay the transactional lender.",
  },
  {
    title: "Repayment of Loan",
    content:
      "The transactional lender is repaid from the proceeds of the B to C transaction. The investor keeps the profit from the sale, less any fees or interest charged by the lender.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TransactionalFundingCarousel = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <section className="py-16 bg-slate-900 text-center">
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <h1 className="text-4xl font-playfair-display text-slate-100 mb-6">
          How Does Transactional Funding Work?
        </h1>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex space-x-1 rounded-xl bg-slate-900/20 p-1">
            {steps.map((step, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    "w-full py-2.5 text-sm leading-5 font-medium text-slate-100 rounded-lg",
                    "focus:outline-none",
                    selected
                      ? "bg-blue-500 shadow"
                      : "text-white hover:bg-white/[0.12] hover:text-white"
                  )
                }
              >
                Step {index + 1}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-4">
            {steps.map((step, index) => (
              <Tab.Panel
                key={index}
                className="rounded-xl bg-slate-700 p-6 text-left text-slate-100"
              >
                <h2 className="text-2xl font-semibold mb-2">{step.title}</h2>
                <p className="text-lg">{step.content}</p>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
};

export default TransactionalFundingCarousel;
