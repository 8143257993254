import React from "react";
import heroImage from "../../images/about-hero.jpg";

const HeroHome = ({ children }) => {
  return (
    <section className="relative">
      <div
        className="absolute max-lg:h-[38rem] inset-0 bg-slate-900 pointer-events-none -z-10 [clip-path:polygon(0_0,_5760px_0,_5760px_calc(100%_-_352px),_0_100%)]"
        aria-hidden="true"
      >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-10"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>
      </div>

      <div className="relative xl:max-w-7xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-20 md:pt-40 md:pb-44">
          <div className="max-w-6xl mx-auto md:max-w-none md:flex max-lg:flex-col md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 md:space-y-0">
            <div
              className="text-center md:text-left md:max-w-2xl lg:max-w-lg"
              data-aos="fade-right"
            >
              <h1 className="h1 font-playfair-display text-slate-100 mb-4 uppercase">
                Transactional Funding
              </h1>
              <p className="text-xl text-slate-400 mb-8">
                Looking for funding to complete your wholesale deal? We offer
                transactional funding to support your A to B purchase, ensuring
                your B to C sale proceeds smoothly.{" "}
                <span className="text-slate-100 font-bold">
                  Our funding covers the entire purchase amount with no down
                  payment required.
                </span>{" "}
                With our quick and easy process, we can even provide funding on the
                same day. Contact us to get started.
              </p>
            </div>

            {/* Form */}
            <div className="max-lg:max-w-fit mx-auto h-full" data-aos="fade">
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroHome;
