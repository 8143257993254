import React, { useState } from "react";
import { useSidebar } from "../../pages/admin/useSidebar";
import { useAuth0 } from "@auth0/auth0-react";

function DashboardHeader() {
  const { isOpen, setIsOpen } = useSidebar();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { logout, user } = useAuth0();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="flex items-center justify-between px-6 py-4 bg-slate-900  border-b-4 border-indigo-600">
      <div className="flex items-center">
        <button
          className="text-gray-500 focus:outline-none lg:hidden"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6H20M4 12H20M4 18H11"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      <div className="flex items-center">
        <div className="relative">
          <button
            className="relative z-10 block w-8 h-8 overflow-hidden rounded-full shadow focus:outline-none"
            onClick={toggleDropdown}
          >
            <img
              className="object-cover w-full h-full"
              src={user.picture}
              alt="Your avatar"
            />
          </button>

          {dropdownOpen && (
            <div
              className="fixed inset-0 z-10 w-full h-full"
              onClick={toggleDropdown}
            />
          )}

          {dropdownOpen && (
            <div className="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl">
              <a
                href="/"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
                Log out
              </a>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default DashboardHeader;
