import React from "react";
import { MdOutlineDone } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import cn from "classnames";

const framer_success = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
  transition: { duration: 0.3 },
};

const SuccessMessage = ({ message, className }) => {
  return (
    <AnimatePresence>
      <motion.div
        className="flex flex-col justify-center items-center text-green-400 gap-1 px-2 font-semibold rounded-md"
        {...framer_success}
      >
        <div className="text-9xl ">
          <MdOutlineDone />
        </div>
        <h2
          className={cn(
            "font-playfair-display text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl",
            className
          )}
        >
          {message}
        </h2>
      </motion.div>
    </AnimatePresence>
  );
};

export default SuccessMessage;
