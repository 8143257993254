import React from "react";
import { Input } from "../../../components/ui/input";
import FormLayout from "./form-layout";

const DetailedFormStep3 = ({ methods, onSubmit, actions }) => {
    const handleSubmit = (data) => {
      actions.updateAction(data);
      onSubmit(data);
    };
    
  return (
    <FormLayout methods={methods} onSubmit={handleSubmit}>
        <Input
        id="id-ab-file"
        name="file01"
        label="A-B Purchase/Acquisition Contract Upload"
        type="file"
        validation={{
          required: "Please upload complete application form",
        }}
      /> 
      <Input
        id="id-bc-file"
        name="file02"
        label="B-C Resale/Disposition Contract Upload"
        type="file"
        validation={{
          required: "Please upload complete application form",
        }}
      />
    </FormLayout>
  );
};

export default DetailedFormStep3;
