import { createContext, useContext, useState } from "react";

const FormContext = createContext();

const HomeFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};

const useHomeFormContext = () => {
  return useContext(FormContext);
};

export { HomeFormProvider, useHomeFormContext };
