import { useForm } from "react-hook-form";
import useFormSubmit from "./useFormSubmit";
import { ENDPOINTS } from "../constants";

const useReferralFormSubmit = ({setOnError, setOnSuccess}) => {
  const methods = useForm();

  const preSubmit = (data) => {
    data.phone = data.phone.replace(/[\s()+-]/g, "");
  };

  const onSuccess = (data) => {
    localStorage.setItem("referralform", "true");
    if(typeof setOnSuccess === 'function') {
      setOnSuccess(true)
    }
  };

  const onError = (error) => {
    if(typeof setOnError === 'function') {
      setOnError(error)
    }
  };

  const { handleSubmit } = useFormSubmit({
    methods,
    uri: ENDPOINTS.REFERRAL(),
    preSubmit,
    onSuccess,
    onError,
  });

  return {
    methods,
    handleSubmit,
  };
};

export default useReferralFormSubmit;
