import React, { useState } from "react";
import DetailedFormStep1 from "./components/detailed-form-s1";
import DetailedFormStep2 from "./components/detailed-form-s2";
import DetailedFormStep3 from "./components/detailed-form-s3";
import DetailedFormStep4 from "./components/detailed-form-s4";
import ProgressBar from "../../components/ui/progress-bar";

const FormStep = ({ step, methods, onSubmit, actions }) => {
  switch (step) {
    case 1:
      return <DetailedFormStep1 methods={methods} onSubmit={onSubmit} actions={actions} />;
    case 2:
      return <DetailedFormStep2 methods={methods} onSubmit={onSubmit} actions={actions} />;
    case 3:
      return <DetailedFormStep3 methods={methods} onSubmit={onSubmit} actions={actions} />;
    default:
      return <DetailedFormStep4 methods={methods} onSubmit={onSubmit} actions={actions} />;
  }
};

const DetailedForm = ({ methods, handleSubmit, actions }) => {
  const [step, setStep] = useState(1);
  const totalSteps = 4;

  const nextStep = () => setStep((prev) => Math.min(prev + 1, totalSteps));
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));

  const onSubmit = (data) => {
    actions.updateAction(data);
    if (step < totalSteps) {
      nextStep();
    } else {
      handleSubmit(data);
    }
  };

  return (
    <div className="flex flex-col items-center p-2">
      <ProgressBar step={step} totalSteps={totalSteps} />

      <div className="pb-2 rounded-md w-full">
        <FormStep step={step} methods={methods} onSubmit={onSubmit} actions={actions} />
      </div>

      <div className="flex justify-between gap-x-12">
        <button
          type="button"
          onClick={prevStep}
          disabled={step === 1}
          className="bg-blue-500 text-white p-3 rounded-full hover:bg-gray-400 disabled:opacity-50"
          aria-label="Previous Step"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        {step < totalSteps ? (
          <button
            type="button"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-blue-500 text-white p-3 rounded-full hover:bg-blue-600"
            aria-label="Next Step"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        ) : (
          <button
            type="button"
            onClick={methods.handleSubmit(handleSubmit)}
            className="bg-green-500 text-white p-3 rounded-full hover:bg-green-600"
            aria-label="Submit Form"
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default DetailedForm;