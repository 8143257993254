const useFormSubmit = ({ methods, uri, preSubmit, onSuccess, onError }) => {
  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      if (typeof preSubmit === "function") {
        await preSubmit(data);
      }
      const response = await fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const error = await response.json();
        if (typeof onError === "function") {
          onError(error.error);
        }
      }
      else if (typeof onSuccess === "function") {
        onSuccess(data);
      }
    } catch (error) {
      if (typeof onError === "function") {
        onError("Something went wrong...");
      }
    }
  });

  return {
    handleSubmit,
  };
};

export default useFormSubmit;
