import React, {useState} from "react";
import Hero from "../../components/ui/hero";
import Accordion from "../../components/utils/accordion";
import ReferralForm from "../../components/form/referral-form";
import useReferralFormSubmit from "../../hooks/useReferralFormSubmit";
import SuccessMessage from "../../components/success-message";
import ErrorModal from "../../components/ui/error-modal";
import CollaborationCategories from "./collaborationcategories";


const Referral = () => {

  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormAlreadySubmitted, setIsFormAlreadySubmitted] = useState(
    localStorage.getItem("referralform") === "true"
  );

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { methods, handleSubmit } = useReferralFormSubmit({setOnError: setErrorMessage, setOnSuccess: setShowSuccessMessage});

  const handleResetForm = () => {
    localStorage.removeItem("referralform");
    setIsFormAlreadySubmitted(false);
    setShowSuccessMessage(false);
  };

  return (
    <>
      <Hero title="COLLABORATE WITH US"/>
      <div className="max-w-fit mx-auto text-center mb-6">
        <h1 className="h2 font-playfair-display text-black mb-4">
        How it works?
        </h1>
        <p className="text-xl max-w-xl text-slate-400 mx-8">
        We use our large network of real estate professionals; you can join our network and be paid for
        your referrals. When your referral code is used the buyer will receive a $100 discount and you
        will receive a percentage of the transaction fee when they close with us.
        </p>
      </div>
      <CollaborationCategories/>
      <div className="mx-auto max-md:mx-custom">
        <div className="max-w-fit mx-auto py-12 md:py-20 border-t border-slate-50">
              <ul className="mx-auto divide-y divide-slate-200">
                <Accordion title="My Referral code">
                <p className="text-md max-w-xl my-2 text-black">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam
                </p>
                </Accordion>
              </ul>
        </div>
        <h1 className="h3 font-playfair-display text-black mb-4 text-center">
            Become an Affiliate
        </h1>
        {showSuccessMessage || isFormAlreadySubmitted ? (
          <>
            <SuccessMessage message={"Referral code for your email has been created already:)"} />
            <span className="flex justify-center items-center gap-2 text-slate-950">
              Want to create a referral on a new email?
              <span
                className="underline hover:cursor-pointer"
                onClick={handleResetForm}
              >
                Click Here
              </span>
            </span>
          </>
        ) : (
          <ReferralForm methods={methods} handleSubmit={handleSubmit} />
        )}
        <ErrorModal
          message={errorMessage}
          isOpen={!!errorMessage}
          onClose={() => {setErrorMessage(null)}}
        />
      </div>
    </>
  );
};

export default Referral;
