import React, { useState } from "react";
import ConfirmDeleteModal from "./confirmation";

const Modal = ({ isOpen, closeModal, header, additionalData, id, deleteUri }) => {
  const overlayStyle = isOpen ? "block" : "hidden";
  const [showDetails, setShowDetails] = useState(false); 
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <div className={`fixed inset-0 z-50 overflow-auto ${overlayStyle}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        <div className="modal-container bg-white rounded-lg shadow-lg z-50 w-full sm:max-w-lg lg:max-w-2xl">
          <div className="modal-content p-6">
            <div className="modal-header flex justify-between items-center border-b pb-4">
              <h3 className="modal-title text-lg font-semibold text-gray-800">Lead Details</h3>
              {/* Close button */}
              <button className="modal-close text-red-500 text-2xl focus:outline-none" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body text-gray-700 max-h-96 overflow-y-auto">
              {/* Base information */}
              <div className="mb-6">
                <p className="font-semibold mb-2 text-lg">Base Information</p>
                {header && Object.entries(header).map(([key, value]) => (
                  <div key={key}>
                    <p className="text-sm mb-1">
                      <span className="font-semibold">{key.replace(/_/g, " ").toUpperCase()}: </span>
                      {value}
                    </p>
                  </div>
                ))}
              </div>
              {/* Separator */}
              <hr className="my-6 border-t border-gray-300" />
              {/* Additional fields */}
              {additionalData && showDetails && (
                <div>
                  <p className="font-semibold mb-2 text-lg">Additional Information:</p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {Object.entries(additionalData).map(([key, value]) => (
                      key !== 'AB Application File URL' && key !== 'BC Application File URL' && (
                        <div key={key}>
                          <p className="text-sm font-semibold">{key.replace(/_/g, " ")}:</p>
                          <p className="text-sm">{value}</p>
                        </div>
                      )
                    ))}
                  </div>
                  {/* Download buttons */}
                  <div className="mt-4">
                    {additionalData['AB Application File URL']&& (
                      <a
                        href={additionalData['AB Application File URL']}
                        className="text-blue-500 hover:underline mr-4"
                        rel="noopener noreferrer"
                      >
                        Download AB Application
                      </a>
                    )}
                    {additionalData['BC Application File URL'] && (
                      <a
                        href={additionalData['BC Application File URL']}
                        className="text-blue-500 hover:underline"
                        rel="noopener noreferrer"
                      >
                        Download BC Application
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
         
          {
            additionalData && 
            <div className="modal-footer bg-gray-100 py-3 px-6 space-x-2 flex justify-end">
            <button
              className="text-blue-500 font-semibold hover:text-blue-700"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? "Hide Details" : "Show Details"}
            </button>
          </div>
          }
          <div className="modal-footer bg-gray-100 py-3 px-6 space-x-2 flex justify-end">
             <button
              className="text-red-500 font-semibold hover:text-red-700"
              onClick={() => setShowDeleteConfirmation((prev) => !prev)}
            >
              Delete
          </button>
          </div>
         
        </div>
      </div>
      <ConfirmDeleteModal isOpen={showDeleteConfirmation} closeModal={() => setShowDeleteConfirmation(prev => !prev)} itemId={id} uri={deleteUri}/>
    </div>
  );
};

export default Modal;
