import React from "react";

const TableLayout = ({ columns, children }) => {
  return (
    <table className="w-full">
      <colgroup>
        {columns.map((c, index) => (
          <col key={index} style={{ width: `${100 / columns.length}%` }} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {columns.map((c) => (
            <th
              key={c.id}
              className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-black uppercase border-b border-gray-200 bg-gray-50"
            >
              {c.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default TableLayout;
