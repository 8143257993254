


function formatPhoneNumber(phoneNumber) {
    // Remove non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    // Format to (XXX)-XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
}

function formatDollarAmount(amount) {
    // Format to American dollar format
    return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

export function transformData(data) {

    const keyMapping = {
    id: "ID",
    entity_title: "Entity Title",
    leadId: "Lead ID",
    property_address: "Property Address",
    property_state: "Property State",
    ab_purchase_price: "AB Purchase Price",
    bc_purchase_price: "BC Purchase Price",
    closing_date: "Closing Date",
    is_attorney_aware: "Is Attorney Aware",
    escrow_name: "Escrow Name",
    attorney_email: "Attorney Email",
    attorney_phone: "Attorney Phone",
    same_transactions_attorney: "Same Transactions Attorney",
    ab_application_file_url: "AB Application File URL",
    bc_application_file_url: "BC Application File URL",
    referral_code: "Referral Code",
    channel: "Channel"
};

    const channelMapping = {
    "0": "Google",
    "1": "Bigger Pockets",
    "2": "Facebook",
    "3": "Carrot",
    "4": "Referral",
    "5": "Other"
};

    const transformedData = {};

    for (const oldKey in data) {
        if (data.hasOwnProperty(oldKey)) {
            const newKey = keyMapping[oldKey];
            let value = data[oldKey];

            // Transform booleans to "Yes" or "No"
            if (typeof value === 'boolean') {
                value = value ? "Yes" : "No";
            }

            // Map channel values
            if (oldKey === "channel") {
                value = channelMapping[value];
            }

            // Format phone numbers
            if (oldKey === "attorney_phone") {
                value = formatPhoneNumber(value);
            }

            // Format dollar amounts
            if (oldKey === "ab_purchase_price" || oldKey === "bc_purchase_price") {
                value = formatDollarAmount(value);
            }

            transformedData[newKey] = value;
        }
    }

    return transformedData;
}

