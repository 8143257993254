import React from "react";
import HeroSupport from "../../components/hero-support";

const Contact = () => {
  return (
    <div className="flex flex-col overflow-hidden">
      <HeroSupport heading={"CONTACT US"} />
    </div>
  );
};

export default Contact;
