export default function AboutContent() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 my-9">
        <div className="pb-12 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <div className="text-lg text-slate-500">
              <p className="mb-8">
                AtoB Funding was founded by real estate investors. We understand the dificulties buyring
                and selling properties.
                <p className="my-2">
                Our Goal is to simplify your real estate transaction. We achieve this by taking financial technology
                approach coupled with proprietary software to both organize and streamline the entire process.
                This starts with the initial uploaded of the contract, continues through the interface with the closing firm
                and ends with a smooth transaction.{"\n"}
                </p>
                Don’t hesitate to reach out; there’s a good chance we have dealt with a similar situation and can help
                you navigate around the obstacle.
              </p>
              <strong className="mb-8">The AtoB Funding Team</strong>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
