import React from "react";
import heroImage from "../images/about-hero.jpg";

const HeroAbout = () => {
  return (
    <section className="relative bg-slate-900">
      <div
        className="absolute inset-0 bg-cover bg-center opacity-10"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 z-10">
        <div className="py-32 md:pt-40 md:pb-40">
          <div className="max-w-3xl mx-auto text-center pb-16">
            <h1 className="h1 font-playfair-display text-slate-100">
              OUR STORY
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroAbout;
