import { MdCached } from "react-icons/md";

const Loading = () => {
  return (
    <tr>
      <td colSpan="100%" className="text-center py-4">
        <div className="flex items-center justify-center text-gray-600">
          <MdCached size={24} className="mr-1 animate-spin" />
          Loading...
        </div>
      </td>
    </tr>
  );
};

export default Loading;
