import React from "react";
import HeroAbout from "../../components/hero-about";
import AboutContent from "./content";

const About = () => {
  return (
    <>
      <HeroAbout />
      <AboutContent />
    </>
  );
};

export default About;
