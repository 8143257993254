export const formatNumber = (number, prev) => {
  if (number.length < prev.length) {
    return number;
  }
  number = number.replace(/[\s()+-]/g, "");

  if (number.length > 10) {
    number = number.slice(0, 10);
  }
  const countryCode = number.slice(0, 3);
  const firstPart = number.slice(3, 6);
  const lastPart = number.slice(6);

  if (number.length === 1) {
    return `(${number})`;
  } else if (number.length === 3) {
    return `(${countryCode})`;
  } else if (number.length <= 6) {
    return `(${countryCode}) ${firstPart}`;
  } else {
    return `(${countryCode}) ${firstPart}-${lastPart}`;
  }
};
