import React from "react";
import { Input, PhoneInput } from "../../../components/ui/input";
import FormLayout from "./form-layout";

const DetailedFormStep1 = ({ methods, onSubmit, actions }) => {
  const handleSubmit = (data) => {
    actions.updateAction(data);
    onSubmit(data);
  };
  
  return (
    <FormLayout methods={methods} onSubmit={handleSubmit}>
      <Input
        id="id-name"
        name="name"
        label="Applicant Name / Company / Entity Name"
        type="text"
        validation={{
          required: `Required`,
          pattern: {
            value: /^[\p{L}\s']+$/u,
            message: `Names can only contain alphabets, letters, spaces, and apostrophes.`,
          },
          minLength: {
            value: 3,
            message: `Name must be at least 3 characters long.`,
          },
        }}
      />
      <Input
        id="id-email"
        name="email"
        label="Email"
        type="email"
        validation={{
          required: "Required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Please enter a valid email address.",
          },
        }}
      />
      <PhoneInput
        id="id-phone"
        name="phone"
        label="Phone"
        type="text"
        validation={{
          required: "Required",
        }}
      />
      <Input
        id="id-contact-name"
        name="entity_title"
        label="Contact Name"
        type="text"
        validation={{
          required: `Required`,
          pattern: {
            value: /^[\p{L}\s']+$/u,
            message: `Names can only contain alphabets, letters, spaces, and apostrophes.`,
          },
          minLength: {
            value: 3,
            message: `Name must be at least 3 characters long.`,
          },
        }}
      />
      <Input
        id="id-street"
        name="street"
        label="Property Address"
        type="text"
        validation={{
          required: "Required",
        }}
      />
      <Input
        id="id-city"
        name="city"
        label="City"
        type="text"
        validation={{
          required: "Required",
        }}
      />
      <Input
        id="id-state"
        name="state"
        label="State"
        type="text"
        validation={{
          required: "Required",
        }}
      />
      <Input
        id="id-zip"
        name="zip"
        label="Zip"
        type="text"
        validation={{
          required: "Required",
        }}
      />
    </FormLayout>
  );
};

export default DetailedFormStep1;
