import { useForm } from "react-hook-form";
import useFormSubmit from "./useFormSubmit";
import { ENDPOINTS } from "../constants";

const useAttorneyFormSubmit = ({ setOnSuccess }) => {
  const methods = useForm();

  const preSubmit = (data) => {
    data.phone = data.phone.replace(/[\s()+-]/g, "");
  };

  const onSuccess = (data) => {
    setOnSuccess(true);
    localStorage.setItem("attorneyform", "true");
  };

  const onError = (error) => {
    console.error(error);
  };

  const { handleSubmit } = useFormSubmit({
    methods,
    uri: ENDPOINTS.ATTORNEY(),
    preSubmit,
    onSuccess,
    onError,
  });

  return {
    methods,
    handleSubmit,
  };
};

export default useAttorneyFormSubmit;
