import { MdInfoOutline } from "react-icons/md";

const NoData = () => {
  return (
    <tr>
      <td colSpan="100%" className="text-center py-4">
        <div className="flex items-center justify-center text-blue-600">
          <MdInfoOutline size={24} className="mr-1" />
          No data available
        </div>
      </td>
    </tr>
  );
};

export default NoData;
