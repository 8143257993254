import React from "react";
import useFetch from "../../hooks/useFetch";
import Loading from "./loading";
import Error from "./error";
import NoData from "./no-data";
import TableLayout from "./table-layout";

const withDataFetching =
  (WrappedComponent) =>
  ({ uri, deleteUri, isClickable, ...props }) => {
    const { data, isLoading, error } = useFetch(uri);
    if (isLoading)
      return (
        <TableLayout {...props}>
          <Loading />
        </TableLayout>
      );
    if (error)
      return (
        <TableLayout {...props}>
          <Error />
        </TableLayout>
      );
    if (!isLoading && data.length === 0)
      return (
        <TableLayout {...props}>
          <NoData />
        </TableLayout>
      );
    return (
      <TableLayout {...props}>
        <WrappedComponent data={data} deleteUri={deleteUri} isClickable = {isClickable}/>
      </TableLayout>
    );
  };

export default withDataFetching;
