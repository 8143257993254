import React, { useState, useEffect } from "react";
import { StateMachineProvider, createStore } from "little-state-machine";
import useDetailedFormSubmit from "./hooks/useDetailedFormSubmit";
import SuccessMessage from "../../components/success-message";
import DetailedForm from "./detailed-form";
import Hero from "../../components/ui/hero";

createStore({
  formData: {
    // Step 1
    name: "",
    email: "",
    phone: "",
    entity_title: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    // Step 2
    ab_purchase_price: "",
    bc_purchase_price: "",
    closing_date: "",
    is_attorney_aware: "",
    firm_name:"",
    escrow_name: "",
    attorney_email: "",
    attorney_phone: "",
    same_transactions_attorney: "",
    // Step 3
    ab_application_file_url: null,
    bc_application_file_url: null,
    // Step 4
    referral_code:"",
    channel:""
  }
});

const DetailedFormPageContent = () => {

  useEffect(() => {
    const sections = document.getElementsByTagName('section');
    const firstSection = sections[0];
      if (firstSection) {
        firstSection.scrollIntoView({ behavior: 'smooth' });
      }
  }, []);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isFormAlreadySubmitted, setIsFormAlreadySubmitted] = useState(
    localStorage.getItem("homedetailedform") === "true"
  );

  const {methods, handleSubmit, actions} = useDetailedFormSubmit({
    setOnSuccess: setShowSuccessMessage,
  });

  const handleResetForm = () => {
    localStorage.removeItem("homedetailedform");
    setIsFormAlreadySubmitted(false);
    setShowSuccessMessage(false);
    actions.updateAction({ formData: {} });
  };

  return (
    <>
      <Hero title="Get Funded Now"/>
      <div className="max-w-7xl mx-auto max-sm:px-6">
        {showSuccessMessage || isFormAlreadySubmitted ? (
          <>
            <SuccessMessage
              message="We'll be in touch soon :)"
              className="text-gray-950"
            />
            <span className="flex justify-center items-center gap-2 text-black">
              Want to submit the form again?
              <span
                className="underline hover:cursor-pointer"
                onClick={handleResetForm}
              >
                Click Here
              </span>
            </span>
          </>
        ) : (
          <>
            <div
              className="text-justify max-sm:text-center md:text-center md:max-w-2xl text-gray-900"
              data-aos="fade-right"
            >
              <h2 className="h2 font-playfair-display mb-4">Apply Now</h2>
              <p className="text-xl mb-8">
              </p>
            </div>
            <DetailedForm methods={methods} handleSubmit={handleSubmit} actions={actions} />
          </>
        )}
      </div>
    </>
  );
};

const DetailedFormPage = () => (
  <StateMachineProvider>
    <DetailedFormPageContent />
  </StateMachineProvider>
);

export default DetailedFormPage;