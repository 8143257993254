import React from 'react';

const ProgressBar = ({ step, totalSteps }) => {
  return (
    <div className="flex items-center justify-center mb-8">
      {Array.from({ length: totalSteps }, (_, index) => (
        <React.Fragment key={index}>
          {/* Step Circles for larger screens */}
          <div className={`flex items-center ${index < totalSteps - 1 ? 'mr-4' : ''}`}>
            <div
              className={`h-10 w-10 flex items-center justify-center rounded-full
                ${step === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-500'}
                hidden md:flex`}
            >
              {index + 1}
            </div>
            {index < totalSteps - 1 && (
              <div
                className={`h-1 bg-blue-500 hidden md:block`}
                style={{ width: '50px', margin: '0 8px' }}
              />
            )}
          </div>

          {/* Dots for mobile screens */}
          <div className={`flex items-center ${index < totalSteps - 1 ? 'mr-4' : ''}`}>
            <div
              className={`h-3 w-3 rounded-full ${step === index + 1 ? 'bg-blue-500 border-white border-2' : 'bg-gray-300'}
                md:hidden`}
            />
            {index < totalSteps - 1 && (
              <div
                className={`h-1 bg-blue-500 md:hidden`}
                style={{ width: '20px', margin: '0 8px' }}
              />
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressBar;
