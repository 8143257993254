import React from "react";
import { Link } from "react-router-dom";

const FeaturesBlock = () => {
  return (
    <section className="relative bg-white mb-8">
    {/* Dark background */}
    <div
      className="absolute inset-0 bg-slate-200 pointer-events-none -z-10 h-96 md:mb-64"
      aria-hidden="true"
    ></div>

    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 ">
      <div className="py-8 md:py-12">
        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center pb-8 md:pb-20">
          <h2 className="text-2xl font-playfair-display text-black">
          By working with us we can reduce the liability that comes with working with investors, increase your collectable fees and simplify your pre-closer’s work load
          </h2>

          <div className="max-w-sm mx-auto md:max-w-none grid gap-12 md:grid-cols-3 md:gap-x-10 md:gap-y-10 items-start">
          <article className="flex flex-col items-center" data-aos="fade-up">
            <p className="relative block group mt-8 mb-4" href="#0">
              <div className="w-16 h-16 bg-gradient-to-b from-blue-500 to-blue-600 rounded-full -top-8 left-8">
                <svg
                  className="w-16 h-16 fill-current"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="text-white"
                    d="m30.152 39.848.672-.408C32.24 33.632 33.92 32 35.96 26.768a10.714 10.714 0 0 0-.432-2.28c-.288-.096-.888-.336-1.44-.336-1.776.48-3.48 1.632-5.208 2.088-1.272.336-.576 2.352.36 3.312.672-.384 1.872-1.008 2.28-.96-1.248 2.112-3 6.12-3 7.704 0 .528-.48.816-.48 1.104 0 .288.12.6.144.936.384-.24.48-.12.48.264.264.192.672.504 1.032.816.144-.744.384-1.56.888-1.464l-.6 1.704c.072.072.12.144.168.192Z"
                  />
                </svg>
              </div>
            </p>
            <div>
            <h3 className="h4 mb-2">
              <p
                className="text-black hover:underline hover:decoration-blue-100"
                href="#0"
              >
              Mitigate Liability
              </p>
            </h3>
            <p className="text-black">
            Pass-through funding exposes
            It isn’t as simple as having a disclosure in the closing docs
            </p>
            </div>
          </article>


          <article className="flex flex-col items-center" data-aos="fade-up" data-aos-delay="100">
            <p className="relative block group mt-8 mb-4" href="#0">
              <div className="w-16 h-16 bg-gradient-to-b from-blue-500 to-blue-600 rounded-full -top-8 left-8">
                <svg
                  className="w-16 h-16 fill-current"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="text-white"
                    d="M25.508 39.044c.312 0 .672-.024 1.008-.048-.096-.432.384-.528.816-.096 1.488-.216 3.12-.624 4.416-.888l.6-.624c1.104 0 .456.624 1.44.432.168-1.008.552-.456 1.92-1.176l.072.168c-.264.192-.72.408-1.152.576v.216c1.584-.528 1.848-1.32 2.928-1.464 1.224-.168 2.184-.048 2.52-.792l-1.728-.096-.168-.24h1.008l-.096-.216c-1.032 0-1.44-.168-2.4-.576-2.016.192-5.328.912-7.632.768 1.296-1.512 3.72-2.592 5.712-3.648.024.36-.384.6-.744.816l.072.216 1.344-.744c.048-.72.552-1.344 1.272-1.44.6-.744.864-1.776.864-2.76 0-1.08-1.2-2.208-3.024-2.208l.096-.264c-1.968.192-5.4.36-6.792 1.176-.504.288-.456.696-.792.984.48.36.024.528.504 1.2.624.888.648 1.488 1.152 1.2 1.056-.576 2.4-1.176 3.384-1.176.48 0 .864.168 1.104.504 0 1.488-6.744 3.792-9.288 7.632.48.096.312.48.072.84.504.768.84 1.728 1.512 1.728Zm7.344-5.976.912-.48-.048-.264-.912.6.048.144Zm7.08 3.216-.096-.216-1.128.216c.144.168.72.048 1.224 0Z"
                  />
                </svg>
              </div>
            </p>
            <div>
            <h3 className="h4 mb-2">
              <p
                className="text-black hover:underline hover:decoration-blue-100"
              >
              Reduce Work Load
              </p>
            </h3>
            <p className="text-lg text-black">
            Our propriety software organizes the documents needed for your pre-closer to perform the
            difficult task of closing a single property via two transactions.
            </p>
            </div>
          </article>

          {/* 3rd article */}
          <article className="flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
            <p className="relative block group mt-8 mb-4" href="#0">
              <div className="w-16 h-16 bg-gradient-to-b from-blue-500 to-blue-600 rounded-full -top-8 left-8">
                <svg
                  className="w-16 h-16 fill-current"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="text-white"
                    d="M25.53 40.038c1.607-.216 3.071-.576 4.655-1.08.048-.36.288-.6.552-.624l.12.408a37.15 37.15 0 0 0 2.352-.912l.12.264c1.416-.72 2.64-1.2 3.984-2.376l.312.144c1.392-1.248 1.632-2.904 1.632-4.728-.624-.912-1.416-1.656-2.784-2.112.36-.72.768-1.752.984-2.784-.12-.648-.408-1.224-.864-1.776-.84-.072-2.088-.24-3.264-.24l-.192-.216a18.485 18.485 0 0 0-1.152-.048c-1.776 0-4.056 1.032-4.056 1.944 0 .624.048 1.248.192 1.824l.268-.172c.578-.368 1.127-.671 1.46-.38l-1.656.792c.048.144.12.336.192.48 1.296-.672 3.96-1.608 5.64-1.608-.456.984-2.376 2.496-3.672 2.832l-.24 1.056c.456 1.248.864 1.032 2.16 1.68l.12-.288c1.8.216 3.336 0 3.96.72-.312 1.464-3.984 2.712-5.4 3.12-.504.144-.84.144-.936-.096-1.416.744-3.36.696-5.064.696-.24.768-.336 1.608.048 2.544l.72-.312c.144.288-.432.696-.456.96-.024.24.12.312.264.288Zm7.703-2.352-.144-.264.744-.312.072.216-.672.36Zm-4.344 1.248-.048-.264c.216-.048.384-.096.552-.12a.588.588 0 0 1 .48.12l-.984.264Z"
                  />
                </svg>
              </div>
            </p>
            <div>
            <h3 className="h4 mb-2">
              <p
                className="text-black hover:underline hover:decoration-blue-100"
                href="#0"
              >
              Increase fees
              </p>
            </h3>
            <p className="text-lg text-black">
            Our model includes doc prep fees to increase your bottom line; schedule a call with us to discuss.
            </p>
            </div>
          </article>
        </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default FeaturesBlock;
