import { useForm } from "react-hook-form";
import useFormSubmit from "./useFormSubmit";
import { ENDPOINTS, STATUS } from "../constants";
import { useNavigate } from "react-router-dom";


const useHomeFormSubmit = ({ setOnSuccess }) => {
  const methods = useForm();

  const navigate = useNavigate();

  const preSubmit = (data) => {
    data.phone = data.phone.replace(/[\s()+-]/g, "");
  };

  const onSuccess = (data) => {
    const { status } = data;
    if (status === STATUS.HIGH) {
      navigate("/detailed-form");
    }
    setOnSuccess(true);

    localStorage.setItem("homeform", "true");
    localStorage.setItem("name", data.name);
    const countryCode = data.phone.slice(0, 3);
    const firstPart = data.phone.slice(3, 6);
    const lastPart = data.phone.slice(6);
    localStorage.setItem("phone", `(${countryCode})-${firstPart}-${lastPart}`);
    localStorage.setItem("email", data.email);
  };

  const onError = (error) => {};

  const { handleSubmit } = useFormSubmit({
    methods,
    uri: ENDPOINTS.HOMEFORM(),
    preSubmit,
    onSuccess,
    onError,
  });

  return {
    methods,
    handleSubmit,
  };
};

export default useHomeFormSubmit;
