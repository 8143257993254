import React, { useState } from "react";
import HeroHome from "./hero-home";
import FeaturesBlock from "../../components/features-block";
import Target from "../../components/target";
import HomeForm from "../../components/form/home";
import useHomeFormSubmit from "../../hooks/useHomeFormSubmit";
import SuccessMessage from "../../components/success-message";
import { HomeFormProvider } from "../../components/form/home/context";

const Home = () => {
  const [isFormAlreadySubmitted, setIsFormAlreadySubmitted] = useState(
    localStorage.getItem("homeform") === "true"
  );

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { methods, handleSubmit } = useHomeFormSubmit({
    setOnSuccess: setShowSuccessMessage,
  });

  const handleResetForm = () => {
    localStorage.removeItem("homeform");
    setIsFormAlreadySubmitted(false);
    setShowSuccessMessage(false);
  };

  return (
    <HomeFormProvider>
      <HeroHome>
        {showSuccessMessage || isFormAlreadySubmitted ? (
          <>
            <SuccessMessage message={"We'll be in touch soon :)"} />
            <span className="flex justify-center items-center gap-2 text-slate-100">
              Want to submit the form again?
              <span
                className="underline hover:cursor-pointer"
                onClick={handleResetForm}
              >
                Click Here
              </span>
            </span>
          </>
        ) : (
          <HomeForm methods={methods} handleSubmit={handleSubmit} />
        )}
      </HeroHome>
      <FeaturesBlock />
      <Target />
    </HomeFormProvider>
  );
};

export default Home;
