import React from "react";
import Logo from "../../images/SVG_Brand Logo white.svg";
import { NavLink, Link } from "react-router-dom";
import { NAVLINKS } from "../../constants/index";
import MobileMenu from "./mobile-menu";

const Header = () => {
  return (
    <header className={`absolute w-full z-30 max-md:mt-4`}>
      <div className="max-w-7xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-12 md:h-20">
          <div className="shrink-0 mr-4">
            <Link to="/">
              <img src={Logo} width="250" height="250" alt="logo" />
            </Link>
          </div>

          <nav className="hidden xl:flex md:grow">
            {/* Desktop menu as */}
            <ul className="flex grow justify-start flex-wrap items-center">
              {NAVLINKS.map((link) => {
                return (
                  <li key={link.url}>
                    <NavLink
                      to={link.url}
                      className={({ isActive }) =>
                        `"font-medium font-monserrat text-slate-100 ${
                          isActive &&
                          "underline decoration-blue-600 underline-offset-8 decoration-2"
                        } hover:text-blue-600 dark:text-slate-400 dark:hover:text-blue-600 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"`
                      }
                    >
                      {link.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>

            <ul className="flex grow gap-2 justify-end flex-wrap items-center">
              <li className="text-slate-300 hover:text-blue-600">
                <Link
                  to="/attorney"
                  className="btn text-white font-monserrat bg-blue-600 hover:bg-blue-700 w-full group"
                >
                  Attorneys Click Here
                </Link>
              </li>
            </ul>
          </nav>
          <MobileMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
