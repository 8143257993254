import React from "react";
import { Input, Checkbox} from "../../../components/ui/input";
import FormLayout from "./form-layout";
import {Select} from "../../../components/ui/select";

const Channels = [
    { value: "0", label: "Google" },
    { value: "1", label: "Bigger Pockets" },
    { value: "2", label: "Facebook" },
    { value: "3", label: "Carrot" },
    { value: "4", label: "Referral" },
    { value: "5", label: "Other" },
];

const DetailedFormStep4 = ({ methods, onSubmit, actions }) => {
    const handleSubmit = (data) => {
      actions.updateAction(data);
      onSubmit(data);
    };
    
  return (
    <FormLayout methods={methods} onSubmit={handleSubmit}>
     <Input
        id="id-referral"
        name="referral_code"
        label="Referral Code"
        type="text"
      />
      <Select
        name="channel"
        label="How did you hear about us?"
        placeholder="Please Select"
        options={Channels}
      />
      <Checkbox
        name="subscribe"
        label="By clicking here you opt-in to occasional SMS messages for communication purposes"
        id="newsletter-checkbox"
        defaultChecked={false}
      />
    </FormLayout>
  );
};

export default DetailedFormStep4;
