import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/Brand Logo Blue.png";

const ScrollToTopLink = ({ children, to, ...props }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link to={to} {...props} onClick={handleClick}>
      {children}
    </Link>
  );
};

const Footer = () => {
  return (
    <footer>
      <div className="max-w-7xl mx-auto my-12 px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="flex flex-col gap-8 py-8 md:py-12">
          {/* 1st block */}
          <div className="lg:max-w-3xl">
            <div className="mb-2">
              {/* Logo */}
              <ScrollToTopLink
                to="/"
                className="inline-flex text-blue-600 transition duration-150 ease-in-out"
                aria-label="Cruip"
              >
                <img src={Logo} width="250" height="250" alt="logo" />
              </ScrollToTopLink>
            </div>
            <div className="text-lg font-bold text-slate-800">
              Don’t loose out on a deal assigning a contract or using pass
              through funding
            </div>
          </div>

          <div className="flex max-sm:flex-col max-sm:space-y-0 justify-between w-full">
            <div className="flex space-x-8 max-sm:flex-col max-sm:space-x-0">
              <div>
                <h6 className="text-sm text-slate-800 hover:text-blue-600 font-semibold mb-2">
                  <ScrollToTopLink to="/about">About</ScrollToTopLink>
                </h6>
              </div>

              <div>
                <h6 className="text-sm text-slate-800 hover:text-blue-600 font-semibold mb-2">
                  <ScrollToTopLink to="/faqs">FAQs</ScrollToTopLink>
                </h6>
              </div>

              <div>
                <h6 className="text-sm text-slate-800 hover:text-blue-600 font-semibold mb-2">
                  <ScrollToTopLink to="/referral">
                    Affiliate/Collab with us
                  </ScrollToTopLink>
                </h6>
              </div>

              <div>
                <h6 className="text-sm text-slate-800 hover:text-blue-600 font-semibold mb-2">
                  <ScrollToTopLink to="/contact">Contact Us</ScrollToTopLink>
                </h6>
              </div>

              <div>
                <h6 className="text-sm text-slate-800 hover:text-blue-600 font-semibold mb-2">
                  <ScrollToTopLink to="/detailed-form">
                    Get Funds Now
                  </ScrollToTopLink>
                </h6>
              </div>
            </div>

            <div>
              <h6 className="text-sm text-slate-800 hover:text-blue-600 font-semibold mb-2">
                <ScrollToTopLink to="/attorney" className="btn text-white font-monserrat bg-blue-600 hover:bg-blue-700 w-full group">
                  Attorneys Click Here
                </ScrollToTopLink>
              </h6>
            </div>
          </div>
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-6 md:py-8 border-t border-slate-200">
          {/* Social as */}
          <ul className="flex space-x-6 mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <ScrollToTopLink
                className="text-blue-500 hover:text-blue-600 transition duration-150 ease-in-out"
                to="#0"
                aria-label="Twitter"
              >
                <svg
                  className="w-5 h-5 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m6.329 1 4.369 5.594L15.75 1h3.068L12.12 8.414 20 18.5h-6.172l-4.833-6.116L3.464 18.5H.395l7.169-7.928L0 1h6.329Zm-.925 1.684H3.583l11.093 14.04h1.7L5.404 2.684Z" />
                </svg>
              </ScrollToTopLink>
            </li>
            <li>
              <ScrollToTopLink
                className="text-blue-500 hover:text-blue-600 transition duration-150 ease-in-out"
                to="#0"
                aria-label="Facebook"
              >
                <svg
                  className="w-5 h-5 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 10.025C20 4.491 15.52 0 10 0S0 4.491 0 10.025c0 4.852 3.44 8.892 8 9.825v-6.817H6v-3.008h2V7.52a3.508 3.508 0 0 1 3.5-3.509H14v3.008h-2c-.55 0-1 .45-1 1.002v2.005h3v3.008h-3V20c5.05-.501 9-4.772 9-9.975Z" />
                </svg>
              </ScrollToTopLink>
            </li>
            <li>
              <ScrollToTopLink
                className="text-blue-500 hover:text-blue-600 transition duration-150 ease-in-out"
                to="#0"
                aria-label="Telegram"
              >
                <svg
                  className="w-5 h-5 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19.96 2.336a.421.421 0 0 0-.291-.308 1.543 1.543 0 0 0-.788.054S1.358 8.194.358 8.87c-.215.145-.288.23-.324.33-.173.485.366.694.366.694l4.517 1.428a.506.506 0 0 0 .229-.013c1.026-.63 10.332-6.335 10.873-6.527.083-.024.148 0 .131.061-.215.732-8.257 7.664-8.301 7.706a.16.16 0 0 0-.06.143l-.422 4.28s-.176 1.331 1.196 0a38.082 38.082 0 0 1 2.374-2.11c1.553 1.041 3.224 2.192 3.945 2.794.245.23.576.354.916.342.426-.05.774-.35.876-.754 0 0 3.192-12.471 3.298-14.142.011-.162.025-.268.027-.38.005-.13-.008-.26-.04-.387Z" />
                </svg>
              </ScrollToTopLink>
            </li>
            <li>
              <ScrollToTopLink
                className="text-blue-500 hover:text-blue-600 transition duration-150 ease-in-out"
                to="#0"
                aria-label="Github"
              >
                <svg
                  className="w-5 h-5 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.041 0C4.52 0 0 4.612 0 10.25c0 4.526 2.845 8.37 6.862 9.737.502.085.669-.257.669-.513v-1.708c-2.761.598-3.347-1.367-3.347-1.367-.419-1.196-1.088-1.537-1.088-1.537-1.004-.598 0-.598 0-.598 1.004.085 1.506 1.025 1.506 1.025.92 1.537 2.343 1.11 2.929.854.084-.683.335-1.11.67-1.367-2.26-.256-4.603-1.11-4.603-5.039 0-1.11.419-2.05 1.004-2.733 0-.342-.418-1.367.168-2.733 0 0 .836-.257 2.76 1.025.838-.257 1.674-.342 2.511-.342.837 0 1.674.085 2.51.342 1.925-1.367 2.762-1.025 2.762-1.025.586 1.452.167 2.477.084 2.733.669.683 1.004 1.623 1.004 2.733 0 3.93-2.343 4.783-4.603 5.04.335.341.67.939.67 1.879v2.818c0 .256.167.598.67.513 4.016-1.367 6.86-5.21 6.86-9.737C20.084 4.612 15.565 0 10.042 0Z" />
                </svg>
              </ScrollToTopLink>
            </li>
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-slate-500 mr-4">
            © A To B Funding. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
