import React, { useState, useEffect } from "react";

const ErrorModal = ({ message, isOpen, onClose }) => {
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  if (!show) return null;

  return (
    <>
      <div
        className="fixed inset-0 bg-gray-800 bg-opacity-50 z-40 flex items-center justify-center"
        onClick={onClose}
      >
        <div
          className="relative bg-white rounded-lg shadow-lg max-w-sm w-full mx-4 p-6 flex flex-col items-center"
          onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
        >
          <button
            onClick={() => {
              setShow(false);
              if (onClose) onClose();
            }}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <h2 className="text-lg font-bold text-red-600 mb-4">Error</h2>
          <p className="text-sm text-gray-700">{message}</p>
          <button
            onClick={() => {
              setShow(false);
              if (onClose) onClose();
            }}
            className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorModal;
