export const NAVLINKS = [
  {
    name: "Get Funding Now",
    url: "/transactional-funding",
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "FAQ",
    url: "/faqs",
  },
  {
    name: "Affiliates/Collab with us",
    url: "/referral",
  },
  {
    name: "Contact Us",
    url: "/contact",
  },
];

export const DASHBOARDLINKS = [
  {
    name: "Dashboard",
    url: "/admin/dashboard",
  },
  // {
  //   name: "Leads",
  //   url: "/admin/leads",
  // },
  // {
  //   name: "Attorney Leads",
  //   url: "/admin/attorney-leads",
  // },
  // {
  //   name: "Referrals",
  //   url: "/admin/referrals",
  // },
];

export const ENDPOINTS = {
  HOMEFORM: () => "/form/acquire-lead",
  ATTORNEY: () => "/form/attorney",
  CUSTOMER: () => "/form/customer",
  REFERRAL: () => "/api/referral",
  GENERATEPRESIGNEDURL: () => "/s3/generate-presigned-url",
  APPLICATION: "/form/application"
};


export const AFFILIATEROLE = [
    { value: "0", label: "Wholesaler" },
    { value: "1", label: "Hard money lender" },
    { value: "2", label: "Influencer" },
    { value: "3", label: "I hold classes/seminars on investing" },
    { value: "4", label: "Closing attorney" },
    { value: "5", label: "Lender/broker" },
    { value: "6", label: "Other" },
];

export const STATUS = {
  HIGH: "0",
  MEDIUM: "1",
  LOW: "2",
};
