import { useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { NavLink, Link } from "react-router-dom";
import { NAVLINKS } from "../../constants";

export default function MobileMenu() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="flex xl:hidden">
      {/* Hamburger button */}
      <button
        ref={trigger}
        // className={`hamburger ${mobileNavOpen && "active"}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <span className="sr-only">Menu</span>

        <svg
          className="w-6 h-6 fill-current text-slate-100 dark:text-slate-100 origin-center"
          viewBox="0 0 30 30"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!mobileNavOpen ? (
            <>
              <path d="M 3 9 H 27 V 11 H 3 Z" />
              <path d="M 3 16 H 27 V 18 H 3 Z" />
              <path d="M 3 23 H 27 V 25 H 3 Z" />
            </>
          ) : (
            <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
          )}
        </svg>
      </button>

      {/*Mobile navigation */}
      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          as="nav"
          id="mobile-nav"
          className="absolute top-full pb-20 z-20 right-0 w-1/2 max-sm:w-2/3 overflow-scroll bg-white mt-4"
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ul className="px-5 py-2">
            {NAVLINKS.map((link) => {
              return (
                <li key={link.url}>
                  <NavLink
                    to={link.url}
                    className={({ isActive }) =>
                      `flex font-medium ${
                        isActive &&
                        "underline decoration-blue-600 underline-offset-8 decoration-2"
                      } text-slate-800 hover:text-blue-600 py-2`
                    }
                    onClick={() => setMobileNavOpen(false)}
                  >
                    {link.name}
                  </NavLink>
                </li>
              );
            })}

            <li>
              <Link
                to="/attorney"
                className="btn text-white bg-blue-600 hover:bg-blue-700 w-full group"
                onClick={() => setMobileNavOpen(false)}
              >
                Attorneys Click Here
              </Link>
            </li>
          </ul>
        </Transition>
      </div>
    </div>
  );
}
