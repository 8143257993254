import React from "react";

const CollaborationCategories = () => {
  const categories = [
    "Wholesalers",
    "Influencers",
    "I teach a Course",
    "Hardmoney lender",
    "Real Estate Agents",
    "I am a lender that doesn’t offer Transactional Funding",
    "REIA’s",
    "Attorneys",
    "Real Estate Professionals",
  ];

  return (
    <section className="py-8 bg-slate-50">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="h2 font-playfair-display text-black mb-6">
          Who We Collaborate With
        </h2>
        <p className="text-lg max-w-xl mx-auto mb-8 text-slate-400">
          To become an affiliate, you’ll need a referral code. This will provide
          a discount to the person you referred and a payment to you when they
          use our services.
        </p>

        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mx-8">
          {categories.map((category, index) => (
            <div
              key={index}
              className="p-4 bg-white border border-slate-200 shadow-sm hover:shadow-md transition-shadow duration-200 rounded-md"
            >
              <h3 className="text-md font-medium text-black">{category}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CollaborationCategories;
