import React from "react";
import { Input, PhoneInput, Checkbox } from "../ui/input";
import FormLayout from "./form-layout";

const AttorneyForm = ({ methods, handleSubmit }) => {
  return (
    <FormLayout methods={methods} onSubmit={handleSubmit}>
      <Input
        id="id-name"
        name="name"
        label="Name"
        type="text"
        placeholder="Your Name..."
        validation={{
          required: `Required`,
          pattern: {
            value: /^[\p{L}\s']+$/u,
            message: `Names can only contain alphabets, letters, spaces, and apostrophes.`,
          },
          minLength: {
            value: 3,
            message: `Name must be at least 3 characters long.`,
          },
        }}
      />
      <Input
        id="id-email"
        name="email"
        label="Email"
        type="email"
        placeholder="Your Email..."
        validation={{
          required: "Required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Please enter a valid email address.",
          },
        }}
      />
      <PhoneInput
        id="id-phone"
        name="phone"
        label="Phone"
        type="text"
        placeholder="(999)-999-9999"
        validation={{
          required: "Phone number is required",
        }}
      />
      <Input
        id="id-message"
        name="message"
        label="Mssage"
        type="textarea"
        placeholder="Type your messsage..."
      />
      <Checkbox
        name="subscribe"
        label="By clicking here you opt-in to occasional SMS messages for communication purposes"
        id="newsletter-checkbox"
        defaultChecked={false}
      />
    </FormLayout>
  );
};

export default AttorneyForm;
