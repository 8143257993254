import { useState, useEffect } from "react";

const useFetch = (uri) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(uri);
        if (!response.ok) {
          throw new Error("Failed to fetch records");
        }
        const responseData = await response.json();
        setData(responseData);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [uri]);

  return { data, isLoading, error };
};

export default useFetch;
