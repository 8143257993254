export const formatCurrency = (input, prev) => {
  if (input.length < prev.length) {
    return input;
  }
  // Remove any non-numeric characters except for the decimal point
  input = input.replace(/[^0-9.]/g, "");

  // Split the input into integer and decimal parts
  let [integerPart, decimalPart] = input.split(".");

  // Format the integer part with commas
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Ensure the decimal part has at most 2 digits
  if (decimalPart && decimalPart.length > 2) {
    decimalPart = decimalPart.slice(0, 2);
  }

  // Combine the integer and decimal parts
  if (decimalPart !== undefined) {
    return `$${integerPart}.${decimalPart}`;
  } else {
    return `$${integerPart}`;
  }
};
