import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./pages/admin/sidebar";
import DashboardHeader from "./components/ui/dashboard-header";
import { SidebarProvider } from "./pages/admin/useSidebar";

const AdminLayout = () => {
  return (
    <SidebarProvider>
      <div class="flex h-screen bg-gray-200 font-roboto">
        <Sidebar />

        <div class="flex-1 flex flex-col overflow-hidden">
          <DashboardHeader />

          <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
            <div class="container mx-auto px-6 py-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default AdminLayout;
