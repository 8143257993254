import cn from "classnames";
import {
  findInputError,
  isFormInvalid,
  formatNumber,
  formatCurrency,
} from "../../utils";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { MdError } from "react-icons/md";
import { useState } from "react";

export const Input = ({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  className,
  styles,
  value,
  instructions,
  defaultValue
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);
  
  const input_tailwind =
    styles ||
    "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

  return (
    <div className={cn("flex flex-col w-full gap-2", className)}>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-gray-700 text-sm font-bold">
          <p>{label}</p>
          <span className="text-xs text-red-500">{` ${instructions || ""}`}</span>
        </label>
      </div>
      <input
        id={id}
        type={type}
        className={cn(input_tailwind)}
        placeholder={placeholder}
        {...register(name, validation)}
        value={value}
      />
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export const PhoneInput = ({
  name,
  label,
  type,
  value,
  id,
  placeholder,
  validation,
  className,
  styles,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);
  const { onChange, ...methods } = register(name, validation);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    e.target.value = formatNumber(e.target.value, inputValue);
    setInputValue(e.target.value);
    onChange(e);
  };

  const input_tailwind =
    styles ||
    "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

  return (
    <div className={cn("flex flex-col w-full gap-2 mb-6", className)}>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-gray-700 text-sm font-bold">
          {label}
        </label>
      </div>
      <input
        id={id}
        type={type}
        className={cn(input_tailwind)}
        placeholder={placeholder}
        {...methods}
        onChange={handleChange}
        value={value}
      />
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export const CurrencyInput = ({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  className,
  styles,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);
  const { onChange, ...methods } = register(name, validation);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    e.target.value = formatCurrency(e.target.value, inputValue);
    setInputValue(e.target.value);
    onChange(e);
  };

  const input_tailwind =
    styles ||
    "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

  return (
    <div className={cn("flex flex-col w-full gap-2 mb-6", className)}>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-gray-700 text-sm font-bold">
          {label}
        </label>
      </div>
      <input
        id={id}
        type={type}
        className={cn(input_tailwind)}
        placeholder={placeholder}
        {...methods}
        value={inputValue}
        onChange={handleChange}
      />
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export const Checkbox = ({
  name,
  label,
  id,
  validation,
  className,
  styles,
  value,
  instructions,
  defaultChecked,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const checkbox_tailwind =
    styles ||
    "form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out";

  return (
    <div className={cn("flex items-center gap-2", className)}>
      <input
        id={id}
        type="checkbox"
        className={cn(checkbox_tailwind)}
        {...register(name, validation)}
        defaultChecked={defaultChecked}
        checked={value}
      />
      <label htmlFor={id} className="text-gray-700 text-sm font-bold">
        <p>{label}</p>
        <span className="text-xs text-red-500">{` ${instructions || ""}`}</span>
      </label>
      <AnimatePresence mode="wait" initial={false}>
        {isInvalid && (
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        )}
      </AnimatePresence>
    </div>
  );
};


const InputError = ({ message }) => {
  return (
    <motion.p
      className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
