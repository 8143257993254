import React from "react";
import Logo from "../images/Brand Logo white.png";
import { Link } from "react-router-dom";
import heroImage from "../images/about-hero.jpg";

const HeroSupport = ({ heading }) => {
  return (
    <section className="relative">
      {/* Dark background */}
      <div
        className="absolute inset-0 bg-slate-900 pointer-events-none -z-10 "
        aria-hidden="true"
      >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-10"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>
      </div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-20 md:pt-40 md:pb-44">
          {/* Hero content */}
          <div className="max-w-xl mx-auto md:max-w-none md:flex md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 md:space-y-0">
            {/* Content */}
            <div
              className="md:text-left md:min-w-[30rem]"
              data-aos="fade-right"
            >
              <h1 className="h1 font-playfair-display text-slate-100 mb-4">
                {heading}
              </h1>
              <div className="shrink-0 mt-4 mb-4">
                <Link to="/">
                  <img src={Logo} width="250" height="250" alt="logo" />
                </Link>
              </div>
              <p className="text-xl text-slate-400 mb-8">
              Feel free to contact us by phone or email
              </p>
            </div>
          </div>
          <div className="flex max-md:flex-col max-md:space-y-2 items-center max-md:justify-center max-w-2xl gap-2">
            <div>
              <Link
                className="btn text-white bg-blue-600 hover:bg-slate-900 hover:border-white w-[18rem]"
                to="#0"
              >
                info@atobfunding.com
              </Link>
            </div>
            <div>
              <Link
                className="btn text-white bg-slate-800 hover:bg-black hover:border-white w-[18rem]"
                to="tel:+18773863875"
              >
                (877) 386-3875
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSupport;
