import React from "react";
import { useFormContext } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../utils";

const Radio = ({ name, label, value, checked, validation }) => {
  const { register } = useFormContext();
  return (
    <li className="max-w-full pr-2 border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
      <div className="flex items-center ps-3">
        <input
          type="radio"
          value={value}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          {...register(name, validation)}
        />
        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
        </label>
      </div>
    </li>
  );
};

export const RadioGroup = ({ name, elements }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  return (
    <ul
      className={`items-center w-full max-md:flex-col text-sm font-medium text-gray-900 bg-white border  rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white ${
        isInvalid ? "border-red-600" : "border-gray-200"
      }`}
    >
      {elements.map((el) => (
        <Radio
          key={el.value}
          name={name}
          label={el.label}
          value={el.value}
          validation={{ required: el.required }}
        />
      ))}
    </ul>
  );
};

export default RadioGroup;
