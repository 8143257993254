import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import useFormSubmit from "../../../hooks/useFormSubmit";
import { ENDPOINTS } from "../../../constants";


const updateAction = (state, payload) => ({
  ...state,
  formData: {
    ...state.formData,
    ...payload
  }
});

const useDetailedFormSubmit = ({ setOnSuccess }) => {
  const { actions } = useStateMachine({ updateAction });
  const methods = useForm();

  const preSubmit = async (data) => {
    try {
       const files = [data.file01[0], data.file02[0]];

      // Create FormData object
      const formData = new FormData();

      // Append files to FormData
      files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
        formData.append(`folderName${index + 1}`, index === 0 ? 'ab_application' : 'bc_application');
      });

      // Send formData to backend
      const response = await fetch("/api/uploadFiles", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const result = await response.json();

      // Update the form data with the returned file URLs
      data.ab_application_file_url = result.file1Url;
      data.bc_application_file_url = result.file2Url;
  
  
      // Modify other form data as needed
      data.phone = data.phone.replace(/[\s()+-]/g, "");
      data.attorney_phone = data.attorney_phone.replace(/[\s()+-]/g, "");
      data.ab_purchase_price = data.ab_purchase_price.replace(/[^0-9.]/g, "");
      data.bc_purchase_price = data.bc_purchase_price.replace(/[^0-9.]/g, "");
  

      delete data.file01;
      delete data.file02;
  
      actions.updateAction(data);
    } catch (error) {
      console.error("Error during file upload and form submission:", error);
    }
  };
  

  const onSuccess = (data) => {
    localStorage.setItem("homedetailedform", "true");
    setOnSuccess(true);
  };

  const onError = (error) => {
    console.error(error);
  };

  const { handleSubmit } = useFormSubmit({
    methods,
    uri: ENDPOINTS.APPLICATION,
    preSubmit,
    onSuccess,
    onError,
  });

  return {
    methods,
    handleSubmit,
    actions
  };
};

export default useDetailedFormSubmit;