import { Input, PhoneInput, CurrencyInput } from "../../../components/ui/input";
import FormLayout from "./form-layout";
import { Select } from "../../../components/ui/select";


const SelectionOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
];

const DetailedFormStep2 = ({ methods, onSubmit, actions }) => {
const handleSubmit = (data) => {
      actions.updateAction(data);
      onSubmit(data);
};
    
  return (
    <FormLayout methods={methods} onSubmit={handleSubmit}>
        <CurrencyInput
        id="id-a-b-purchase-price"
        name="ab_purchase_price"
        label="A-B Purchase Price"
        type="text"
        validation={{
          required: "Required",
        }}
      />
      <CurrencyInput
        id="id-b-c-purchase-price"
        name="bc_purchase_price"
        label="B-C Resale Price"
        type="text"
        validation={{
          required: "Required",
        }}
      />
      <Input
        id="id-closing-date"
        name="closing_date"
        label="Closing Date"
        type="date"
        validation={{
          required: "Required",
        }}
      />
      <Select
        name="is_attorney_aware"
        label="Is Closing Attorney or Title Company Aware of the Double Closing?"
        placeholder="Please Select"
        options={SelectionOptions}
        validation={{ required: "Required" }}
      />
      <Input
        id="id-escrow-name"
        name="firm_name"
        label="Firm Name"
        type="text"
        validation={{
          required: `Required`,
          pattern: {
            value: /^[\p{L}\s']+$/u,
            message: `Names can only contain alphabets, letters, spaces, and apostrophes.`,
          },
          minLength: {
            value: 3,
            message: `Name must be at least 3 characters long.`,
          },
        }}
      />
      <Input
        id="id-escrow-name"
        name="escrow_name"
        label="Closing Attorney or Title/Escrow Representative Contact Name"
        type="text"
        validation={{
          required: `Required`,
          pattern: {
            value: /^[\p{L}\s']+$/u,
            message: `Names can only contain alphabets, letters, spaces, and apostrophes.`,
          },
          minLength: {
            value: 3,
            message: `Name must be at least 3 characters long.`,
          },
        }}
      />
      <Input
        id="id-attorney-email"
        name="attorney_email"
        label="Closing Attorney or Title/Escrow Representative Email"
        type="email"
        validation={{
          required: "Required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Please enter a valid email address.",
          },
        }}
      />
      <PhoneInput
        id="id-attorney-phone"
        name="attorney_phone"
        label="Closing Attorney or Title Representative Phone"
        type="text"
        validation={{
          required: "Required",
        }}
      />
      <Select
        name="same_transactions_attorney"
        label="Are both AB & BC transactions closing with the same Title Company or Closing Attorney?"
        placeholder="Please Select"
        options={SelectionOptions}
      />

    </FormLayout>
  );
};

export default DetailedFormStep2;
